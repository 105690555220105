import React, { useEffect, useState } from "react";

const TopLayerComp = ({ url }) => {
  const [isImage, setIsImage] = useState(false);
  useEffect(() => {
    setIsImage(url.includes("mp4") || url.includes("webm"));
  }, [url]);

  return (
    <div
      
    >
      {!isImage ? (
        <div className={`absolute flex justify-center  left-0  right-0   bottom-0 `}>
          <img width="100%" height="100%" src={url} alt="imageUrl" />
        </div>
      ) : (
        <div className={`absolute flex justify-center  left-0  right-0 h-2/3   bottom-0 `}>
          <video
            width="100%"
            height="100%"
            autoPlay={true}
            playsInline
            loop
            src={url}
            muted={true}
          ></video>
        </div>
      )}
    </div>
  );
};

export default TopLayerComp;
