import axios from "axios";

export const fetchVideos = async (url) => {
  let _url = `${process.env.REACT_APP_BASE_URL}/${url}`;
  try {
    const response = await axios.get(`${_url}`, {
      headers: {
        "X-API-KEY": process.env.REACT_APP_API_KEY,
      },
    });
    return response;
  } catch (error) {
    console.log(error.code);
    return error.code;
  }
};
export const retrieveVideo = async (projectId, body) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/llm/${projectId}`,
      body,
      {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
        },
      }
    );
    console.log( response)
    return response;
  } catch (error) {
    return error.code;
  }
};

export const getUserStatus = async ( body) => {
    
  
  try {
  const headers = {
    "accept": "application/json",
    "content-type": 'application/json',
    "authorization": `Bearer ${process.env.REACT_APP_DEEPSET_API_KEY}`
};
let url = `${process.env.REACT_APP_DEEPSET_STATUS_URL}`
debugger
    const response = await axios.post(
      url,
      JSON.stringify(body),
      {
        headers: headers,
      }
    );
    debugger
    return response;
  } catch (error) {
    return error.code;
  }
};
