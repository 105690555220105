import { retrieveVideo } from "../api/api";

export class LlmService {
  async getVideo(projectId, body) {
    const result = await retrieveVideo(projectId, body);

    if(result && result.data){
        return result.data
    }else{
        return ''
    }
  }
}
