import React, { useEffect, useState } from "react";

const ImageOrVideoComp = ({ url }) => {
  const [isImage, setIsImage] = useState(false);
  useEffect(() => {
    
    setIsImage(url.includes("mp4") || url.includes("webm"));
  }, [url]);

  return !isImage ? (
    <div>
      {url && (
        <img
          className="fullscreen"
          width="100%"
          height="100%"
          src={url}
          alt="imageUrl"
        />
      )}
    </div>
  ) : (
    <video
      className="fullscreen"
      width="100%"
      height="100%"
      autoPlay={true}
      playsInline
      loop
      src={url}
      muted={true}
    ></video>
  );
};

export default ImageOrVideoComp;
